import VueRouter from 'vue-router';
import Vue from 'vue';
Vue.use(VueRouter);

const routes=[
	{
		path:"/",
		name:"home",
		meta: { title: '职小子 - 为你的职场加分 - 万瑞世家商业管理单位网站' },
		component:()=>import('../views/home/index.vue')
	} ,{
		path:"/onlineTeaching",
		name:"onlineTeaching",
		meta: { title: '企业服务' },
		component:()=>import('../views/online-teaching/index.vue')
	},
	{
		path:"/study",
		name:"study",
		meta: { title: '行业资讯' },

		component:()=>import('../views/study/index.vue')
	} ,
	{
		path:"/about",
		name:"about",
		meta: { title: '关于我们' },
		component:()=>import('../views/about/index.vue')
	}
	,{
		path:"/detail",
		meta: { title: '行业资讯' },
		name:"detail",
		component:()=>import('../views/detail/index.vue')
	}

];
const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})
export default router;