<template>
  <div
    id="app"
    style="
      padding-top: 65px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    "
  >
    <page-header ref="cc" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, true);
  },
  created() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollEvent, true);
    });
  },
  methods: {
    scrollEvent(e) {
      // console.log("高度", e.target.scrollTop);
      //获取滚动时的高度
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      let scrollTop = e.target.scrollTop;
      console.log(this.$refs.cc.isS);
      if (scrollTop >= 65) {
        this.$refs.cc.menuShow = false;
      } else {
        if (this.$refs.cc.isS) {
          return;
        }
        this.$refs.cc.menuShow = true;
      }
    },

    changes() {
      console.log("aaa");
    },
  },
  components: {
    "page-header": () => import("@/components/header.vue"),
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* //text-align: center; */
  color: #2c3e50;
}
</style>
