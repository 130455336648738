import Vue from 'vue'
import App from './App.vue'
import router from './router';
import './assets/css/common.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
Vue.use('Vant')
Vue.use(VueAxios,axios);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
